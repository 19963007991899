<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { menuMethods } from "@/state/helpers";
import Scheduler from "@/components/scheduler/scheduler.vue"
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Documents",
    },
    components: {
        Layout,
        //eslint-disable-next-line
        PageHeader,
        Scheduler,
    },
    data() {
        return {
            title: "Scheduler",
            items: [

            ],
        };
    },
    methods:{
        ...menuMethods,
    },
        mounted() {
            //this.$eventHub.$emit('menuPageLoaded');
            setTimeout(()=>{
                this.setLoading(false);
            }, 500)
        },
};
</script>

<template>
    <Layout>
        <!--<PageHeader :title="title" :items="items" /> -->
        <Scheduler/>
    </Layout>
</template>
