<!-- Main component for scheduler view -->
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showModal"
      title="Add New Event"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="name">Event Name</label>
              <input
                id="name"
                v-model="event.title"
                type="text"
                class="form-control"
                placeholder="Insert Event name"
                :class="{ 'is-invalid': submitted && $v.event.title.$error }"
              />
              <div
                v-if="submitted && !$v.event.title.required"
                class="invalid-feedback"
              >
                This value is required.
              </div>
            </div>
          </div>
        </div>

        <div class="text-right pt-5 mt-3">
          <b-button variant="light" @click="hideModal" class="">Close</b-button>
          <b-button
            style="margin-left: 10px"
            type="submit"
            variant="success"
            class="ml-4"
            >Create event</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="eventModal"
      title="Edit Event"
      title-class="text-black font-18"
      hide-footer
      body-class="p-3"
    >
      <form @submit.prevent="editSubmit">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="name">Event Name</label>
              <input
                id="name"
                v-model="editevent.editTitle"
                type="text"
                class="form-control"
                placeholder="Insert Event name"
              />
            </div>
          </div>
        </div>
        <div class="text-right p-3">
          <b-button variant="light" @click="closeModal">Close</b-button>
          <b-button
            style="margin-left: 10px"
            class="ml-1"
            variant="danger"
            @click="confirm"
            >Delete</b-button
          >
          <b-button
            style="margin-left: 10px"
            class="ml-1"
            variant="success"
            @click="editSubmit"
            >Save</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
// https://fullcalendar.io/docs/selectable
import FullCalendar from "@fullcalendar/vue";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import {
  levelComputed, //* variables relating to level
  activityComputed, //* variables relating to performance activity
  adminComputed,
} from "@/state/helpers";
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      pageEvents: [],
      showModal: false,
      event: {
        title: "",
      },
      editevent: {
        editTitle: "",
      },
      submitted: false,
      submit: false,
      newEventData: {},
      eventModal: false,
      calendarOptions: {
        // Set the height of the calendar to view height
        height: "750px",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth",
        },
        plugins: [dayGridPlugin, interactionPlugin, bootstrapPlugin],
        initialView: "dayGridMonth",
        firstDay: 1,
        selectable: false,
        editable: true,
        droppable: false,
        dateClick: this.handleDateClick,
        select: this.handleDateSelect,
        eventClick: this.editEvent,
        eventDrop: this.handleEventDrop,
        events: this.pageEvents,
      },
      objective_id: 94, //* hardcoded sample objective id
      baseUrl: process.env.VUE_APP_API_BASEURL,
    };
  },
  validations: {
    event: {
      title: { required },
    },
  },
  computed: {
    ...levelComputed,
    ...activityComputed,
    ...adminComputed,
    user_id() {
      return this.selected_user_id !== null
        ? Number(this.selected_user_id)
        : this.core.id;
    },
    activity_data() {
      return [];
    },
  },
  methods: {
    loadRequiredData() {
      window.axios
        .get(`${this.baseUrl}api/scheduler/${this.company_id}`)
        .then((res) => {
          this.pageEvents = res.data.entries;
          this.hydrateEvents();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hydrateEvents() {
      this.calendarOptions.events = this.pageEvents;
    },
    hideModal(e) {
      e.preventDefault();
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    deleteEvent(info) {
      this.deletePost(info);
      this.eventModal = false;
    },
    editEvent(info) {
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.eventModal = true;
    },
    closeModal() {
      this.eventModal = false;
    },
    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent(this.edit.id);
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },
    handleDateClick(arg) {
      let dateSelection = {
        start: arg.dateStr,
        end: arg.dateStr,
      };
      this.newEventData = dateSelection;
      this.showModal = true;
    },
    handleDateSelect(selectionInfo) {
      let dateSelection = {
        start: selectionInfo.startStr,
        end: selectionInfo.endStr,
      };
      this.newEventData = dateSelection;
      this.showModal = true;
    },
    handleEventDrop(eventDropInfo) {
      this.pageEvents = this.pageEvents.map((event) => {
        if (event.id == eventDropInfo.event.id) {
          eventDropInfo.event.client_id = event.client_id;
          let startDate = event.start;
          let endDate = event.end;
          let diffInTime = new Date(endDate).setTime(new Date(endDate).getTime() + 86400000) - new Date(startDate)
          let newStart = eventDropInfo.event.start;
          let newEnd = new Date(newStart).setTime(new Date(newStart).getTime() + diffInTime)
          const end_date = new Date(newEnd).toISOString().split("T")[0];
          const start_date = new Date(newStart).getTime() + 86400000;
          const start = new Date(start_date).toISOString().split("T")[0];
          event.start = start;
          event.end = end_date;
          this.editPost(event);
        }
      });
      this.hydrateEvents();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageEvents.push({
          title: this.event.title,
          start: this.newEventData.start,
          end: this.newEventData.end,
          allDay: true,
          user_id: this.user_id,
          objective_id: this.objective_id,
        });
        this.hydrateEvents();
        this.successmsg();
        this.createPost();
        this.showModal = false;
      }
    },
    editSubmit() {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      let updateEvent = {};
      this.pageEvents = this.pageEvents.map((event) => {
        if (event.id == this.edit.id) {
          event.title = editTitle;
          updateEvent = event;
        }
        return event;
      });
      this.hydrateEvents();
      this.successmsg();
      this.editPost(updateEvent);
      this.eventModal = false;
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      }).then(() => {
        this.submitted = false;
        this.submit = false;
        this.event = {};
        this.newEventData = {};
        this.editevent.editTitle = "";
      });
    },
    createPost() {
      window.axios
        .post(`${this.baseUrl}api/scheduler/create/${this.company_id}`, {
          title: this.event.title,
          start: this.newEventData.start,
          end: this.newEventData.end,
          allDay: false,
          user_id: this.user_id,
          objective_id: this.objective_id,
        })
        .then(() => {
          this.loadRequiredData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editPost(event) {
      window.axios
        .post(`${this.baseUrl}api/scheduler/edit/${event.id}`, {
          event: event,
        })
        .then(() => {
          this.loadRequiredData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletePost(id) {
      // Get the event from pageEvents with the id
      const event = this.pageEvents.find((event) => event.id == id);
      window.axios
        .post(`${this.baseUrl}api/scheduler/delete/${event.id}`, {
          client_id: event.client_id,
        })
        .then(() => {
          this.loadRequiredData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.hydrateEvents();
    // console.log('Loaded...', window.webix);
    this.loadRequiredData();
  },
};
</script>

<style>
</style>